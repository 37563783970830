.ContractsList {
  padding-right: 30px;
  padding-left: 30px;
  .spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    margin-bottom: -30px;
  }
}

.boxCompanyList {
  max-width: 828px;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 12px;

  .companyList {
    box-sizing: border-box;
    padding-left: 92px;
    padding-right: 92px;
    margin: 40px auto 0;
    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #f0f0f0;
      border-radius: 30px;
      height: 107px;
      width: 8px;
    }
  }
}

.pagination {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: -24px;
  .paginationBttns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 290px;
    width: 100%;
    margin: 0 auto;
    li {
      list-style-type: none;
      border: 1px solid #11578c;
      box-sizing: border-box;
      border-radius: 8px;
      width: 45px;
      height: 45px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    a {
      font-weight: 900;
      font-size: 26px;
      line-height: 31px;
      text-align: right;
      color: #11578c;
    }
    .previous,
    .next {
      border: none;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .paginationActive {
      background-color: #11578c;
      a {
        color: #fff;
      }
    }
  }
}

.btnPayNow {
  max-width: 453px;
  margin: 43px auto 52px;
}

@media only screen and (max-width: 768px) {
  .boxCompanyList {
    .companyList {
      padding: 10px 26px;
    }
  }
}
@media only screen and (max-width: 570px) {
  .ContractsList {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 130px;
  }
  .boxCompanyList {
    background-color: transparent;
    box-shadow: none;

    .companyList {
      padding: 10px;
    }
  }
  .col {
    position: relative;
  }

  .btnPayNow {
    margin: 0;
    max-width: 310px;
    width: 100%;
    position: absolute;
    bottom: -70px;
    left: calc(50% - 155px);
    transform: translate(0, -3%);
  }
}
