.CreditCardRegistrationPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  .backgroundContainer {
    position: absolute;
    z-index: -1;
    top: 0;
    width: 100%;
    height: 407px;
    background-image: url(../../../assets/images/blueArcBackground.svg);
    background-repeat: no-repeat;
    background-size: 100% 407px;
  }

  .title {
    position: absolute;
    z-index: 1;
    top: 118px;
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    color: #ffffff;
  }

  .creditCardImage {
    width: 406px;
    height: 240px;
  }

  .radioButtons {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  .shortFieldsContainer {
    width: 453px;
    display: flex;
    justify-content: space-between;
  }

  .formFieldContainer {
    width: 453px;
    margin-bottom: 12px;
    &.short {
      width: 30%;
    }
  }
}

.radioButtonContainer {
  display: block;
  position: relative;
  padding-left: 19px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}

.radioButtonContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  width: 12px;
  background: #e1dfdf;
  border-radius: 50%;
}

.radioButtonContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

.radioButtonContainer input:checked ~ .checkmark {
  background: #11578c;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.radioButtonContainer input:checked ~ .checkmark:after {
  display: block;
}

@media only screen and (max-width: 1024px) {
  .title {
    top: 70px !important;
  }
}

@media only screen and (max-width: 570px) {
  .CreditCardRegistrationPage {
    .backgroundContainer {
      height: 260px !important;
      background-size: 100% 260px !important;
    }
    .formFieldContainer {
      width: 360px !important;
      &.short {
        width: 176px !important;
      }
    }

    .creditCardImage {
      width: 318px !important;
      height: 194px !important;
      margin-top: 17px;
    }

    .shortFieldsContainer {
      width: 360px !important;
    }

    .radioButtons {
      margin-bottom: 28px !important;
    }

    .title {
      top: 101px !important;
      font-size: 28px !important;
      line-height: 34px !important;
    }
  }
}
