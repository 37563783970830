.title {
  font-weight: bold;
  font-size: 35px;
  line-height: 42px;
  text-align: center;
  color: #666666;
}


@media (max-width: 570px) {
  .title {
    font-size: 28px;
    line-height: 34px;
  } 
}