.ErrorMessage {
  position: relative;
  margin-top: 6px;
  padding-left: 13px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #f0491f;

  &::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    display: block;
    width: 8px;
    height: 8px;
    background: #f0491f;
    border-radius: 50%;
  }

  &::after {
    content: '';
    position: absolute;
    top: -50px;
    right: 20px;
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(../../../assets/images/errorSign.svg);
  }

  &.outside {
    &::after {
      right: -30px;
    }
  }
}

@media only screen and (max-width: 962px) {
  .ErrorMessage {
    &::after {
      top: -45px;
      right: 14px;
    }
  }
}
@media only screen and (max-width: 400px) {
  .ErrorMessage {
    &.outside {
      &::after {
        display: none;
      }
    }
  }
}
