.StyledButtonRed {
  position: relative;
  width: 100%;
  height: 65px;
  background: #fe4141;
  //border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border: none;
  border-radius: 10px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;

  .click {
    display: none !important;
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    width: 57px;
    height: 86%;
    background: rgba(255, 255, 255, 0.31);
    border-radius: 50%;
  }

  &.narrow {
    height: 47px;
  }

  &:focus {
    background: #fe4141;
    box-shadow: 0 0 0 3px #ff8888;
    box-sizing: border-box;
    border: none;
  }

  &:hover {
    background: #c92d2d;
  }

  &:disabled {
    background: #fe8f8f;
    border: none;
    &.isLoading {
      background: #fe4141;
    }
  }

  &:active {
    &:not(:disabled) {
      .click {
        display: block !important;
      }
    }
  }

  &.secondary {
    color: #fe4141;
    background-color: transparent;
    border: 1px solid #fe4141;
    box-sizing: border-box;
    border-radius: 10px;
    &:hover {
      background-color: inherit;
      border: 1px solid #c92d2d;
      color: #c92d2d;
    }
    &:active {
      .click {
        background: rgba(254, 65, 65, 0.3) !important;
      }
    }
    &:focus {
      color: #fe4141;
      background-color: inherit;
      box-shadow: 0 0 0 3px #ff8888;
      box-sizing: border-box;
      border: none;
    }
    &:disabled {
      background-color: inherit;
      border: 1px solid #fe4141;
      color: #fe8f8f;
      &.isLoading {
        color: #11578c;
        background-color: transparent;
        border: 1px solid #fe4141;
      }
    }
  }

  &.subtle {
    background-color: transparent;
    border: 1px solid #d6d8e7;
    color: #fe4141;
    &:hover {
      color: #fe4141;
    }
    &:focus {
      box-shadow: 0 0 0 5px rgba(255, 136, 136, 0.2);
      border: none;
    }
    &:active {
      color: #fe4141;
      .click {
        background: rgba(254, 65, 65, 0.1) !important;
      }
    }
    &:disabled {
      background-color: transparent;
      border: 1px solid rgba(219, 220, 234, 0.59);
      color: rgba(254, 65, 65, 0.2);
      &.isLoading {
        border: 1px solid #dbdcea;
        color: #11578c;
      }
    }
  }

  &.text {
    background-color: transparent;
    color: #fe7070;
    &:hover {
      color: #c92d2d;
    }
    &:focus {
      box-shadow: none;
      color: #fe4141;
    }
    &:active {
      color: #fe4141;
    }
    &:disabled {
      background-color: transparent;
      color: #fe8f8f;
      &.isLoading {
        color: #fe4141;
      }
    }
  }

  &.red {
    background: #fe4141;
    color: #ffffff;
  }

  &.white {
    background-color: white;
    color: #11578c;
  }

  &.grayBorder {
    border: 1px solid #c2c2c2;
    color: #ffffff;
  }
}

@media only screen and (max-width: 962px) {
  .FormButton {
    height: 52px;
  }
}

.spinner {
  display: inline-block;
  position: relative;
  width: 26px;
  height: 26px;
  .loadingMessage {
    display: none;
  }
  &.text {
    width: 76px;
    .loadingMessage {
      display: block;
    }
    div {
      display: none;
    }
  }
  &.secondary {
    div {
      border: 2px solid #fe4141;
      border-color: #fe4141 transparent transparent transparent;
    }
  }
  &.subtle {
    div {
      border: 2px solid #fe4141;
      border-color: #fe4141 transparent transparent transparent;
    }
  }
}
.spinner div {
  position: relative;
  top: 3px;
  left: 2px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  margin: 2px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
