.MobileHeader {
  font-family: 'Lato', sans-serif;
  width: 100%;
  height: 143px;
  background-image: url(../../assets/images/emptyHeader.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .backButton {
    display: block;
    width: 41px;
    height: 32px;
    position: absolute;
    top: 31px;
    left: 24px;
    font-size: 0;
    background-image: url(../../assets/images/backArrow.svg);
    cursor: pointer;
  }
  .burgerButton {
    display: block;
    width: 27px;
    height: 19px;
    position: absolute;
    top: 37px;
    right: 24px;
    font-size: 0;
    background-image: url(../../assets/images/burgerButton.svg);
    cursor: pointer;
  }

  .backDrop {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.32);
  }

  .sideMenu {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 100;
    top: 0;
    right: -278px;
    bottom: 0;
    width: 278px;
    background: #11578c;
    border-radius: 0px 30px 30px 0px;
    transition: right ease-out 0.1s;

    .sideMenuHeader {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
      height: 88px;
      margin-bottom: 48px;

      .logo {
        width: 85px;
        height: 65px;
      }

      .closeButton {
        position: absolute;
        top: 31px;
        right: 24px;
        display: block;
        width: 32px;
        height: 32px;
        font-size: 0;
        background-image: url(../../assets/images/closeButton.svg);
        cursor: pointer;
      }
    }

    .menuButtonsContainer {
      display: flex;
      flex-direction: column;
      padding-left: 25px;

      .dropDown {
        display: flex;
        flex-direction: column;
        margin-top: -3px;
        margin-bottom: 34px;
        padding-left: 40px;

        a {
          margin-bottom: 23px;
          text-decoration: none;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .menuButton {
        display: flex;
        align-items: center;
        margin-bottom: 34px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        text-decoration: none;
        cursor: pointer;

        &.disabled {
          color: rgba(255, 255, 255, 0.58);
          pointer-events: none;

          .account {
            background-image: url(../../assets/images/accountIconDisabled.svg);
          }

          .dashboard {
            background-image: url(../../assets/images/dashboardIconDisabled.svg);
          }

          .history {
            background-image: url(../../assets/images/historyIconDisabled.svg);
          }

          .notifications {
            background-image: url(../../assets/images/notificationsIconDisabled.svg);
          }
        }

        .account {
          display: block;
          width: 24px;
          height: 24px;
          margin-right: 14px;
          font-size: 0;
          background-image: url(../../assets/images/accountIcon.svg);
        }

        .dashboard {
          display: block;
          width: 18px;
          height: 18px;
          margin-right: 13px;
          font-size: 0;
          background-image: url(../../assets/images/dashboardIcon.svg);
        }

        .history {
          display: block;
          width: 24px;
          height: 24px;
          margin-right: 10px;
          font-size: 0;
          background-image: url(../../assets/images/historyIcon.svg);
        }

        .notifications {
          display: block;
          width: 22px;
          height: 22px;
          margin-right: 11px;
          font-size: 0;
          background-image: url(../../assets/images/notificationsIcon.svg);
        }
        .logout {
          display: block;
          width: 22px;
          height: 22px;
          margin-right: 11px;
          font-size: 0;
          background-image: url(../../assets/images/logoutIcon.svg);
        }

        &.dropDownToggle {
          &.disabled {
            span {
              &::after {
                background-image: url(../../assets/images/arrowDownDisabled.svg);
              }
            }
          }

          &.active {
            span {
              &::after {
                transform: rotate(180deg) translate(0, -5px);
              }
            }

            &::after {
              content: '';
              display: block;
              position: absolute;
              left: 0;
              z-index: -1;
              width: 100%;
              height: 44px;
              background: #0d4977;
            }
          }
          span {
            position: relative;
            &::after {
              content: '';
              display: block;
              width: 16px;
              height: 16px;
              position: absolute;
              top: 0;
              right: -23px;
              background-image: url(../../assets/images/arrowDown.svg);
              transform: translate(0, 2px);
            }
          }
        }
      }
    }
  }
}
