.BubblesBottomBackround {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../../../assets/images/grayBubbles.svg);
  background-repeat: no-repeat;
  background-position: left bottom;
}

.Wallet {
  padding-left: 40px;
  padding-right: 40px;
}

.boxWallet {
  max-width: 612px;
  width: 100%;
  margin: 0 auto 71px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 12px;

  .boxWalletBody {
    padding: 57px 15px 31px;
    margin: auto;
  }

  .wrapper {
    margin-top: 47px;
  }

  .addCard {
    width: 457px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding-top: 36px;
    padding-bottom: 40px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      padding-left: 20px;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #666666;
    }
  }
}

@media only screen and (max-width: 570px) {
  .BubblesBottomBackround {
    display: none;
  }
  .Wallet {
    padding-left: 0;
    padding-right: 0;
  }
  .boxWallet {
    background-color: transparent;
    box-shadow: none;

    .boxWalletBody {
      padding: 0px 15px 31px;
    }

    .wrapper {
      margin-top: 20px;
    }

    .title h1 {
      color: #ffffff;
    }
  }

  .addCard {
    width: unset !important;
    padding-left: 10px;
    padding-right: 10px;
  }

  .btnSave {
    display: none;
  }
}
