.CheckOutPage {
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 107px;
}

.boxCheckOutPage {
  max-width: 612px;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 12px;
  padding-top: 28px;

  .boxCheckOutPageBody {
    padding: 0;
  }
}

.amountInfoBox {
  max-width: 453px;
  margin: 0 auto;
  .label {
    margin-top: 30px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    color: rgba(0, 0, 0, 0.8);
  }
}

.boxCard {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 23px;
  .cardImg {
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    border-radius: 15px;
    max-width: 90px;
    width: 100%;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .cardImg {
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .borderCard {
    border: 2px solid #11578c;
  }
}

.form {
  font-family: Roboto, sans-serif;
  max-width: 453px;
  margin: 28px auto 0;

  .titleForm,
  .titleFormMobile {
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.7);
    margin-top: 28px;
    margin-bottom: 22px;
  }

  .titleFormMobile {
    display: none;
  }

  .formRowContainer {
    display: flex;
    margin: 28px auto 0;
    .exDate {
      max-width: 170px;
      .input {
        width: 100%;
      }
    }
    .cvv {
      max-width: 85px;
      .input {
        width: 100%;
      }
    }
    .saveBtn {
      box-sizing: border-box;
      background: #f5f5f5;
      border-radius: 15px;
      width: 160px;
      max-height: 59px;
      padding: 18px;
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      white-space: nowrap;
      cursor: pointer;
      &.active {
        border: 2px solid #11578c;
      }
      .saveCard {
        padding-left: 12px;
      }
    }
    .newAddress {
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.7);
      cursor: pointer;
      margin-right: 17px;
    }
  }

  .otherInfo {
    justify-content: space-between;
  }
}

.btnContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 463px;
  margin: 78px auto 41px;
  button {
    width: 200px;
  }
}

@media only screen and (max-width: 570px) {
  .CheckOutPage {
    padding-bottom: 20px;
  }
  .boxCheckOutPage {
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding-top: 0;

    .boxCheckOutPageBody {
      padding: 0;
    }

    .amountInfoWrapp {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      border-radius: 30px;
      padding: 43px 28px 33px 25px;
      margin-top: 60px;

      .amountInfoBox {
        .label {
          margin-top: 0;
          font-size: 19px;
          line-height: 22px;
        }
        .value {
          margin-top: 15px;
          margin-bottom: 27px;
          font-size: 45px;
          line-height: 53px;
        }
      }
    }
  }

  .form {
    .titleForm {
      display: none;
    }
    .titleFormMobile {
      display: block;
    }

    .otherInfo {
      .exDate,
      .cvv {
        margin-right: 16px;
      }
      .saveBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 9px 19px 8px;
        .saveCard {
          padding-left: 0;
          font-size: 9px;
          line-height: 11px;
          padding-top: 3px;
        }
      }
    }
  }

  .checkBox {
    justify-content: space-between;
  }

  .btnContainer {
    margin: 80px auto 20px;
  }
}

@media only screen and (max-width: 570px) {
  .btnContainer {
    margin: 40px auto 20px;
    flex-direction: column;
    button {
      width: 100%;
    }
    button + button {
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 425px) {
  .form {
    .otherInfo {
      flex-direction: column;
      .exDate {
        width: 100% !important;
        margin-bottom: 28px;
      }
      .cvv {
        margin-bottom: 28px;
      }
    }
    .saveBtn {
      width: 100% !important;
    }
  }
}
