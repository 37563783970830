.SignUpPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  .signupFormBody {
    padding-top: 7px;
  }

  .backgroundContainer {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../../../assets/images/grayBubbles.svg);
    background-repeat: no-repeat;
    background-position: left bottom;
  }

  .formTitle {
    display: flex;
    margin-bottom: 19px;
    justify-content: center;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    color: #666666;
  }

  .signupForm {
    width: 906px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 12px;

    .namesContainer {
      display: flex;
      justify-content: space-between;
      width: 453px;
    }

    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .formFieldContainer {
      width: 453px;
      margin-bottom: 11px;
      &.short {
        width: 211px;
      }
      .termsOfUse {
        position: relative;
        top: -6px;
        display: inline-block;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */
        color: #666666;
      }
    }
  }
}

@media only screen and (max-width: 962px) {
  .signupForm {
    width: 500px !important;
  }
}
