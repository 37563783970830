.CreateNewPasswordPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  .contentContainer {
    position: relative;
    top: -17px;
    width: 453px;

    .errorMessage {
      margin-top: -60px;
    }
    .details {
      text-align: center;
      margin-bottom: 47px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #666666;
    }
    .formFieldContainer {
      margin-bottom: 14px;
    }
    .formFieldContainer + .formFieldContainer {
      margin-bottom: 70px;
    }
    .checkBoxContainer {
      margin-bottom: 65px;
      display: flex;
      justify-content: space-between;
      .forgotPassword {
        position: relative;
        top: -6px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        text-decoration: none;
      }
    }
    .buttonContainer {
      margin-bottom: 162px;
    }
  }
}

@media only screen and (max-width: 570px) {
  .CreateNewPasswordPage {
    .contentContainer {
      top: 0;
      width: 310px;
      margin-top: 49px;
      .details {
        margin-bottom: 37px;
      }
      .formFieldContainer {
        margin-bottom: 26px;
      }
      .formFieldContainer + .formFieldContainer {
        margin-bottom: 70px;
      }
      .buttonContainer {
        margin-bottom: 110px;
      }
    }
  }
}
