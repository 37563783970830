$main: #11578c;
$white: #ffffff;

.ProfilePage {
  padding-bottom: 70px;
}

.box_btn {
  height: 52px;
  margin: 32px auto 48px;
  max-width: 260px;
}

.btn_link {
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid $main;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_link span {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  padding-left: 9px;
  color: $main;
}

.btn_link:hover {
  text-decoration: none;
  color: #002ed1;
}

.info {
  max-width: 501px;
  margin: 0 auto;
}

.info_item {
  background: $white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 11px 20px 15px;

  &.isValid {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: top 32px right 15px;
  }

  &.isInvalid {
    background-image: url(../../../assets/images/errorSign.svg);
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: top 32px right 15px;
  }
}

.info_item + .info_item {
  margin-top: 19px;
}

.info_item .password_circle {
  padding-top: 6px;
  padding-bottom: 7px;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 22px;
}

.info_item .password_circle div {
  width: 9px;
  height: 9px;
  margin-right: 5px;
  background: #666666;
  border-radius: 50%;
}

.info_item h3 {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #666666;
}

.info_item span {
  font-size: 14px;
  line-height: 17px;
  color: rgba(102, 102, 102, 0.75);
}

.buttonLabel {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  &:hover {
    svg {
      path {
        fill: #08395f;
      }
    }
  }
}

@media only screen and (max-width: 570px) {
  .box_btn {
    margin: 22px auto 35px;
    max-width: 152px;
  }

  .btn_link {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .btn_link img {
    width: 17px;
    height: 17px;
  }
  .btn_link span {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding-left: 7px;
  }
  .info {
    padding-left: 37px;
    padding-right: 37px;
  }

  .info_item {
    padding: 14px 21px 15px;
  }
}
