$main: #11578c;
$label: #404040;
$whitegrey: #b1b1b1;

.InvoiceDetailCard {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  cursor: pointer;
}

.LeftWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 21px;
}

.companyInfo {
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .contactBox {
    font-style: normal;
    font-size: 14px;
    line-height: 17px;

    .label {
      font-weight: 900;
      color: $label;
    }
    .value {
      font-weight: 400;
      color: $whitegrey;
    }
  }

  .contactBox + .contactBox {
    margin-left: 15px;
  }
}

.amountBox {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: normal;
  color: #ffffff;
  padding: 21px 10px;
  background: #11578c;
  max-width: 100px;
  text-align: center;
  .amount {
    display: block;
    font-size: 12px;
    line-height: 14px;
  }

  .amountValue {
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
  }
}

@media only screen and (max-width: 570px) {
  .boxImg {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 400px) {
  .companyInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .contactBox + .contactBox {
      margin-left: 0;
    }
  }
  .amountBox {
    .amountValue {
      font-size: 14px;
      white-space: nowrap;
    }
  }
}
