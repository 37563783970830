$main: #11578c;
$label: #404040;
$whitegrey: #b1b1b1;

.CompanyCard {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: 1px solid transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 11px 30px 13px 45px;
  cursor: pointer;
}

.borderCard {
  border: 1px solid #11578c;
}

.LeftWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.companyInfo {
  margin-left: 71px;
  .contactBox,
  .centerItem {
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 4px;
    .label {
      font-weight: 900;
      color: $label;
    }
    .value {
      font-weight: 400;
      color: $whitegrey;
    }
  }

  .centerItem {
    padding: 10px 0;
  }

  .inmubleBox {
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    padding-top: 10px;
    padding-bottom: 4px;
    .inmuble {
      font-weight: 900;
      color: $label;
    }
    .inmubleValue {
      font-weight: 400;
      color: $whitegrey;
    }
  }

  .titularBox {
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    .titular {
      font-weight: 900;
      color: rgba(0, 0, 0, 0.75);
    }
    .titularValue {
      font-weight: 400;
      color: $whitegrey;
    }
  }
}

.amountBox {
  font-style: normal;
  font-weight: 900;
  .amount {
    display: block;
    text-align: right;
    font-size: 12px;
    line-height: 14px;
    color: $whitegrey;
  }

  .amountvalue {
    font-size: 20px;
    line-height: 24px;
    color: $label;
  }
}

@media only screen and (max-width: 768px) {
  .CompanyCard {
    justify-content: space-around;
    padding: 11px 0 13px;
  }

  .companyInfo {
    margin-left: 51px;
  }
}

@media only screen and (max-width: 570px) {
  .CompanyCard {
    padding: 12px 10px 10px 27px;
  }

  .boxImg {
    padding-left: 0;
    padding-right: 0;
  }

  .companyInfo {
    margin-left: 30px;
  }

  .centerItem {
    padding: 15px 0;
  }

  .amountBox {
    align-self: flex-end;
    .amountvalue {
      font-size: 14px;
      line-height: 17px;
      color: $label;
    }
  }
}
