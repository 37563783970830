.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 266px;
  padding-top: 45px;
  background-size: 100% 266px;
  background: #11578c;
  .logo img {
    width: 231px;
    height: 224px;
  }
}

.contentWrapp {
  max-width: 612px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  .title {
    font-weight: bold;
    font-size: 35px;
    line-height: 42px;
    color: #666666;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .total {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-top: 1px dashed rgba(0, 0, 0, 0.4);
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    font-style: normal;
    font-size: 30px;
    line-height: 24px;
    color: #404040;
    margin-bottom: 55px;
    padding-left: 20px;
    padding-right: 20px;
    .label {
      margin-bottom: 15px;
      font-weight: normal;
    }
    .value {
      margin-bottom: 15px;
      font-weight: 900;
    }
  }

  .invoiceCard {
    border-radius: 0;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    border-bottom: none;

    .invoiceCardBody {
      padding: 0;
      .invoiceWrapp {
        max-width: 450px;
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
        .orderInfo {
          display: grid;
          grid-template-columns: 58% 42%;
          gap: 1rem;
          max-width: 450px;
          width: 100%;
          margin: 40px auto 29px;
          .orderInfoItem {
            display: flex;
            align-items: center;
            .boxImg {
              background: #11578c;
              border-radius: 50%;
              min-width: 48px;
              height: 48px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .boxInfoText {
              margin-left: 15px;
              font-style: normal;
              .label {
                font-style: normal;
                font-weight: 900;
                font-size: 16px;
                line-height: 19px;
                color: #404040;
              }
              .value {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #b1b1b1;
              }
            }
          }
        }

        .contentBox {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 20px;
          .label {
            font-weight: 900;
            color: #404040;
          }
          .value {
            font-weight: 400;
            color: #b1b1b1;
          }
        }

        .invoiceItems {
          margin-bottom: 35px;
        }
      }
    }
  }
}

.backButton {
  margin-bottom: 150px !important;
}

@media (max-width: 570px) {
  .contentWrapp {
    .title {
      font-size: 28px;
      line-height: 34px;
    }

    .invoiceCard {
      .invoiceCardBody {
        .invoiceWrapp {
          .orderInfo {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
}
