.CreditCardRegistrationPage {
  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-top: 9px;
      margin-bottom: 82px;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      color: #666666;
    }
    .contentCard {
      width: 361px;
      height: 487px;
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 30px;
      border: none;
      .cardBody {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 83px;
        .scaner {
          position: relative;
          width: 175px;
          height: 175px;
          margin-bottom: 67px;
          background-image: url(../../../assets/images/qrIconBig.svg);
          background-repeat: no-repeat;
          background-position: center;
          border: 2px solid rgba(102, 102, 102, 0.21);
          border-radius: 50%;
          box-sizing: border-box;
          box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);
          &::after {
            content: '';
            position: absolute;
            top: -2px;
            left: -2px;
            display: block;
            width: 175px;
            height: 175px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 50%;
          }
        }
        .details {
          margin-bottom: 44px;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          color: #666666;
        }
      }
    }
  }
}
