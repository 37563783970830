$bgCard: #e5edfb;
$numberCard: #404040;
$label: #b1b1b1;
$delete: #fe4141;

.cardWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.card {
  background: $bgCard;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 14px 35px 17px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 17px;
  height: 102px;

  .boxImgVisa {
    margin-right: 18px;
  }
  .boxImgMasterCard {
    // display: none;
    margin-right: 18px;
  }

  .numberCard {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    color: $numberCard;
  }

  .otherInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 17px;
  }

  .label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $label;
    display: block;
  }

  .value {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: $numberCard;
  }
}

.cardEdit {
  color: $label;
  cursor: pointer;
}

/* .cardDelete {
  background-color: $delete;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding-left: 21px;
  padding-right: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 102px;
  max-width: 74px;
  cursor: pointer;
} */

.deleteLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.75);
  svg {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 570px) {
  .card {
    padding: 14px 26px 17px 14px;

    .boxImgVisa {
      display: none;
    }
    .boxImgMasterCard {
      display: block;
      margin-right: 27px;
    }

    .numberCard {
      font-size: 17px;
    }
  }
}

@media only screen and (max-width: 400px) {
  .card {
    height: unset;
    padding: 14px 14px 17px 14px;
    .boxImgMasterCard {
      margin-right: 10px;
    }

    .otherInfo {
      align-items: flex-start;
    }
  }

  .numberCard {
    font-size: 14px !important;
  }

  .cardDelete {
    padding-left: 17px;
    padding-right: 17px;
  }
}
