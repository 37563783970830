$main: #11578c;
$white: #ffffff;
$whitegrey: #b1b1b1;

.PaymentDetails {
  position: relative;
  padding-left: 27px;
  padding-right: 27px;

  .detailsWrapp {
    max-width: 763px;
    margin: 64px auto 0;
    padding-bottom: 156px;
    display: flex;
    justify-content: space-between;
  }

  .infoCard {
    position: static;
    max-width: 360px;
    max-height: 348px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 29px 27px 43px 23px;
    border: none;

    .infoCardBody {
      padding: 0;
      .infoTopWrapp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 40px;

        .fecha {
          .label {
            color: rgba(0, 0, 0, 0.75);
          }

          .value {
            color: $whitegrey;
          }
        }
        .order {
          .label {
            color: rgba(0, 0, 0, 0.75);
          }

          .value {
            font-weight: 400;
            color: $whitegrey;
          }
        }
      }
      .form {
        font-family: Roboto, sans-serif;
        max-width: 308px;
        width: 100%;
        .formRowContainer {
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;

          &:last-child {
            justify-content: space-between;
          }

          .formFieldCardNumber {
            max-width: 222px;
            width: 100%;
            margin-right: 11px;
          }

          .cardType {
            width: 74px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #f5f5f5;
            border-radius: 15px;
          }

          .formFieldContainer {
            width: 134px;
          }

          .formFieldContainer + .formFieldContainer {
            margin-left: 40px;
          }
        }
      }
    }
  }

  .infoHistory {
    max-width: 360px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    border: none;
    margin-bottom: 80px;

    .infoHistoryBody {
      padding: 0;
      .infoTop {
        padding: 20px 23px 39px 25px;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
        .companyInfo {
          margin-bottom: 23px;
          .topItem {
            font-style: normal;
            font-weight: 900;
            font-size: 12px;
            line-height: 14px;
            color: #404040;
          }

          .contactBox {
            font-style: normal;
            font-size: 16px;
            line-height: 19px;
            padding-top: 11px;

            .label {
              color: #404040;
              font-weight: 900;
            }
            .value {
              color: $whitegrey;
              font-weight: 400;
            }
          }
          .cardWrapp {
            margin-top: 23px;
          }
        }
      }
      .infoBottom {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-top: 37px;
        padding-bottom: 40px;
        font-style: normal;
        font-size: 20px;
        line-height: 24px;
        color: #404040;
        .label {
          margin-bottom: 5px;
          font-weight: normal;
        }
        .value {
          margin-bottom: 5px;
          font-weight: 900;
        }
      }
    }
  }
}

.btnContainer {
  position: absolute;
  bottom: 91px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  //margin: 78px auto 41px;
  //margin-bottom: 80px;
  .innerContainer {
    display: flex;
    justify-content: space-between;
    width: 483px;
    .spinnerContaner {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    button {
      width: 200px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .PaymentDetails {
    padding-bottom: 0;
    .detailsWrapp {
      margin: 56px auto 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    .infoCard {
      margin-bottom: 51px;
    }
    .btnContainer {
      margin: 40px auto -40px;
      width: 100%;
      .innerContainer {
        max-width: 360px;
        flex-direction: column;
        button {
          width: 100%;
        }
        button + button {
          margin-top: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .PaymentDetails {
    .cardType {
      display: none !important;
    }
    .formFieldCardNumber {
      max-width: 100% !important;
      margin-right: 0 !important;
    }
  }
}
