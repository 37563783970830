.EmptyHeader {
  font-family: 'Lato', sans-serif;
  width: 100%;
  height: 174px;
  background-image: url(../../assets/images/emptyHeader.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 962px) {
  .EmptyHeader {
    height: 143px;
  }
}
