.FormField {
  margin-bottom: 0;
  font-family: 'Lato', sans-serif;
  .formControl {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 65px;
    padding-left: 20px;
    padding-right: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #666666;
    background-color: transparent;
    border: 1px solid #666666;
    border-radius: 12px;
  }

  .formControl:read-only {
    background: #f5f5f5;
    border: none;
    pointer-events: none;
  }
  .formLabel {
    position: relative;
    margin-bottom: 6px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
  }
}

@media only screen and (max-width: 962px) {
  .formControl {
    height: 52px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    font-size: 14px !important;
    line-height: 17px !important;
    border-radius: 10px !important;
  }

  .formLabel {
    font-size: 14px !important;
    line-height: 17px !important;
  }
}
