.PaymentUnsuccessfullPage {
  .backgroundContainer {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../../../assets/images/grayBubbles.svg);
    background-repeat: no-repeat;
    background-position: left bottom;
  }
  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 21px;
    .title {
      margin-bottom: 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      color: #666666;
    }
    .failIcon {
      width: 101px;
      height: 101px;
      margin-bottom: 55px;
      background-image: url(../../../assets/images/failIcon.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .details {
      margin-bottom: 272px;
      h2 {
        margin-bottom: 30px;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        color: #666666;
      }
      p {
        display: block;
        width: 332px;
        height: 38px;
        margin-bottom: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #666666;
      }
    }
    .buttonContainer {
      width: 453px;
      margin-bottom: 161px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .PaymentUnsuccessfullPage {
    .contentContainer {
      margin-top: 16px;
      .title {
        margin-bottom: 23px;
        font-weight: bold;
        font-size: 28px;
        line-height: 34px;
      }
      .failIcon {
        margin-bottom: 54px;
        width: 84px !important;
        height: 84px !important;
      }
      .details {
        margin-bottom: 336px;
        h2 {
          margin-bottom: 29px;
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 26px;
        }
        p {
          margin-bottom: 0;
        }
      }
      .buttonContainer {
        margin-bottom: 61px;
        width: 360px;
      }
    }
  }
}
