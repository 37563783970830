.FormField {
  width: 100%;
  position: relative;
  margin-bottom: 0;
  font-family: 'Lato', sans-serif;
  .formControl {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 57px;
    //padding-top: 18px;
    padding-left: 17px;
    padding-right: 17px;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.7);
    background: #f5f5f5;
    border-radius: 15px;
    border: none;
    &::placeholder {
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.7);
      opacity: 1;
    }

    &.notEmpty {
      padding-top: 25px;
    }

    &:focus {
      padding-top: 25px;
    }
  }

  .formControl:read-only {
    background-color: white;
  }
  .formLabel {
    position: absolute;
    z-index: 1;
    top: 8px;
    left: 17px;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.7);
  }
}
