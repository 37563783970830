.PaymentConfirmationPage {
  .backgroundContainer {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../../../assets/images/grayBubbles.svg);
    background-repeat: no-repeat;
    background-position: left bottom;
  }
  .contentContainer {
    position: relative;
    top: -12px;
    display: flex;
    justify-content: center;
    .contentCard {
      margin-bottom: 45px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      border: none;
      .contentCardBody {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 828px;
        padding-top: 40px;
        padding-bottom: 23px;
        .contentCardTitle {
          margin-bottom: 38px;
          font-style: normal;
          font-weight: bold;
          font-size: 40px;
          line-height: 48px;
          text-align: center;
          color: #666666;
        }
        .successIcon {
          width: 108px;
          height: 108px;
          margin-bottom: 18px;
          background-image: url(../../../assets/images/successIcon.svg);
          background-position: center;
          background-repeat: no-repeat;
        }
        .details {
          margin-bottom: 53px;
          h2 {
            text-align: center;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 26px;
            color: #666666;
            margin-bottom: 20px;
          }
          p {
            width: 332px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #666666;
          }
        }
        .detailsCard {
          width: 494px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          border-radius: 12px;
          border: none;
          .detailsCardBody {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 15px;
            padding-bottom: 24px;
            .detailsCardTitle {
              margin-bottom: 39px;
              text-align: center;
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
              color: #666666;
            }
            .paymentDetails {
              display: block;
              list-style: none;
              width: 334px;
              margin: 0;
              padding: 0;
              li {
                display: flex;
                justify-content: space-between;
                margin-bottom: 26px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                color: #666666;
              }
            }
            .buttonsContainer {
              display: flex;
              justify-content: space-between;
              width: 334px;
              .buttonContainer {
                width: 152px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .PaymentConfirmationPage {
    .contentContainer {
      .contentCard {
        margin-bottom: 55px;
        overflow: hidden;
        width: 100%;
        background: transparent;
        box-shadow: none;
        border: none;
        .contentCardBody {
          width: 100%;
          padding-top: 15px;
          .contentCardTitle {
            margin-bottom: 22px;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 34px;
            text-align: center;
            color: #666666;
          }
          .successIcon {
            width: 84px;
            height: 84px;
            margin-bottom: 31px;
            background-size: 70px 70px;
          }
          .details {
            margin-bottom: 27px;
            h2 {
              font-weight: 600;
              font-size: 22px;
              line-height: 26px;
              margin-bottom: 22px;
            }
            p {
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 19px;
            }
          }
          .detailsCard {
            width: 341px;
            display: flex;
            align-items: center;
            .detailsCardBody {
              width: 291px;
              padding-top: 15px;
              padding-bottom: 13px;
              .detailsCardTitle {
                margin-bottom: 23px !important;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
              }
              .paymentDetails {
                width: 291px;
                margin-bottom: 64px;
              }
              .buttonsContainer {
                width: 311px;
                .buttonContainer {
                  width: 152px;
                }
              }
            }
          }
        }
      }
    }
  }
}
