.CountryField {
  //margin-top: 26px;
  display: flex;
  align-items: flex-end;
  height: 93px;
  margin-bottom: 0;
  font-family: 'Lato', sans-serif;
  .label {
    position: absolute;
    top: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
  }

  .dropDown {
    width: 100%;
    div {
      height: 200px;
      width: 100%;
      overflow-y: scroll;
    }
    button {
      height: 65px;
      width: 453px;
      padding-left: 20px;
      display: flex;
      align-items: center;
      border-radius: 12px !important;
      outline: none;
      position: relative;
      &::after {
        position: absolute;
        right: 10px;
        width: 24px;
        height: 24px;
        background-image: url(../../../assets/images/selectArrow.svg);
        background-repeat: no-repeat;
        background-position: center;
        border: none;
      }
    }
  }

  .formControl {
    position: relative;
    box-sizing: border-box;
    //width: 100%;
    height: 65px;
    padding-left: 20px;
    padding-right: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #666666;
    background-color: transparent;
    border: 1px solid #666666;
    border-radius: 12px;
  }

  .formControl:read-only {
    background: #f5f5f5;
    border: none;
    pointer-events: none;
  }
}

@media only screen and (max-width: 962px) {
  .CountryField {
    height: 80px;
    .formControl {
      height: 52px !important;
      padding-left: 16px !important;
      padding-right: 16px !important;
      font-size: 14px !important;
      line-height: 17px !important;
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }

    .label {
      font-size: 14px !important;
      line-height: 17px !important;
    }
    .dropDown {
      button {
        height: 52px;
        font-size: 14px;
        line-height: 17px;
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
      }
    }
  }
}
