.EmailConfirmationPage {
  box-sizing: border-box;
  width: 644px;
  height: 615px;
  margin-top: 30px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 12px;
  font-family: 'Lato', sans-serif;
  .cardBody {
    padding-top: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title {
    margin-bottom: 49px;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    color: #666666;
  }
  .cardText {
    display: flex;
    justify-content: center;
    margin-bottom: 61px;
    p {
      width: 288px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #666666;
      text-align: center;
    }
  }
  .codeContainer {
    display: flex;
    justify-content: space-between;
    width: 404px;
    margin-bottom: 103px;
    input {
      display: block;
      box-sizing: border-box;
      width: 72px;
      height: 74px;
      background: #f5f5f5;
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 50px;
      color: #ffffff;
      text-align: center;
      border-radius: 10px;
      outline: none;
      border: none;
    }
  }

  .buttonContainer {
    width: 453px;
  }
}
.emailFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 362px;
  background-color: #1e4ffc;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
}

@media only screen and (max-width: 962px) {
  .EmailConfirmationPage {
    width: 550px !important;
  }
}

@media only screen and (max-width: 570px) {
  .EmailConfirmationPage {
    box-sizing: border-box;
    max-width: 100%;
    height: auto;
    margin-top: 0;
    margin-bottom: 20px;
    box-shadow: none;
    border: none;
    border-radius: 0;
    font-family: 'Lato', sans-serif;
    .cardBody {
      width: auto;
      padding: 0;
    }
    .title {
      margin-bottom: 41px;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      color: #666666;
    }
    .cardText {
      position: relative;
      left: -10px;
      width: 288px;
      margin-bottom: 0;
      p {
        margin-bottom: 41px;
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #666666;
        text-align: left;
      }
    }
    .codeContainer {
      width: 310px;
      margin-bottom: 61px;
      input {
      }
    }

    .buttonContainer {
      width: 310px;
    }
  }
}
