.ContractsGroups {
  padding-right: 30px;
  padding-left: 30px;
  .spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.boxCompanyGroupList {
  max-width: 828px;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 12px;

  .companyGroups {
    max-height: 570px;
    padding: 10px 92px;
    margin: 40px auto 0;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #f0f0f0;
      border-radius: 30px;
      height: 107px;
      width: 8px;
    }
  }

  .contact {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 10px;
    .label {
      font-weight: 900;
      color: rgba(0, 0, 0, 0.75);
    }
    .value {
      font-weight: 400;
      color: #b1b1b1;
    }
  }

  .groupItem + .groupItem {
    margin-top: 46px;
  }
}

.btnsContainer {
  max-width: 828px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  .btnPayNow {
    width: 300px;
    margin: 43px auto 52px;
  }
}

@media only screen and (max-width: 768px) {
  .boxCompanyGroupList {
    .companyGroups {
      padding: 10px 26px;
    }
  }

  .btnsContainer {
    .btnPayNow {
      width: 100%;
      max-width: 250px !important;
    }
  }
}

@media only screen and (max-width: 570px) {
  .ContractsGroups {
    padding-right: 0;
    padding-left: 0;
  }
  .boxCompanyGroupList {
    background-color: transparent;
    box-shadow: none;

    .companyGroups {
      padding: 10px;
    }

    .contact {
      font-size: 18px;
      line-height: 20px;
    }
  }
  .col {
    position: relative;
  }

  .btnsContainer {
    padding-left: 20px;
    padding-right: 20px;
    .btnPayNow {
      width: 100%;
      max-width: 170px !important;
    }
  }
}
