.EmptyHeaderWithLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 470px;
  padding-top: 44px;
  background-image: url(../../assets/images/blueArcBackground.svg);
  background-repeat: no-repeat;
  background-size: 100% 470px;
  &.incorrectPassword {
    .title {
      position: relative;
      top: -5px;
      margin: 0;
    }
  }
  &.bigger {
    height: 528px;
    background-size: 100% 528px;
  }
  .logo {
    width: 360px;
    height: 350px;
    background-image: url(../../assets/images/bigLogoNew.png);
  }

  .title {
    margin-top: 31px;
    white-space: nowrap;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 31px;
    color: #ffffff;
  }

  i {
    font-size: 0;
  }
}

@media only screen and (max-width: 570px) {
  .EmptyHeaderWithLogo {
    padding-top: 85px;
    height: 383px !important;
    background-size: 100% 383px !important;
    background-image: url(../../assets/images/blueArcBackgroundMobile.svg);
    &.incorrectPassword {
      padding-top: 27px;
    }
    &.wavy {
      height: 594px !important;
      background-image: url(../../assets/images/blueArcBackgroundMobileWavy.svg);
      background-size: 100% 594px !important;
    }
    .logo {
      width: 278px;
      height: 269px;
      background-position: center;
      background-size: contain;
    }
    .title {
      margin-top: 21px;
    }

    .headerButtons {
      position: absolute;
      top: 31px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      height: 32px;
      padding-left: 24px;
      padding-right: 28px;
      i {
        cursor: pointer;
      }
      .goBack {
        display: block;
        width: 41px;
        height: 32px;
        font-size: 0;
        background-image: url(../../assets/images/backArrow.svg);
      }
      .help {
        display: block;
        width: 29px;
        height: 29px;
        font-size: 0;
        background-image: url(../../assets/images/helpButton.svg);
      }
    }
  }
}
