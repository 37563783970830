.ClientCodePage {
  .helpButton {
    position: absolute;
    top: 49px;
    right: 55px;
    display: block;
    width: 29px;
    height: 29px;
    font-size: 0;
    background-image: url(../../../assets/images/helpButton.svg);
  }

  .backgroundContainer {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../../../assets/images/grayBubbles.svg);
    background-repeat: no-repeat;
    background-position: left bottom;
  }

  .cardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 75px;
    .clientCodeCard {
      width: 669px;
      height: 572px;
      margin-bottom: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      border: none;

      .cardBody {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 55px;
        .cardTitle {
          margin-bottom: 45px;
          font-style: normal;
          font-weight: bold;
          font-size: 28px;
          line-height: 34px;
          text-align: center;
          color: #666666;
        }

        .details {
          width: 273px;
          margin-bottom: 64px;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          color: #666666;
        }

        .formFieldContainer {
          position: relative;
          .infoIcon {
            position: absolute;
            z-index: 111;
            right: 15px;
            top: 48px;
            cursor: pointer;
          }
          .tooltip {
            top: -190px;
            right: -147px;
            position: absolute;
            &::after {
              position: absolute;
              bottom: -7px;
              left: calc(50% - 7px);
              content: '';
              display: block;
              width: 15px;
              height: 15px;
              background-color: #015387;
              transform: rotate(45deg);
            }
          }
          width: 453px;
          margin-bottom: 80px;
        }
      }
    }
  }
}

@media only screen and (max-width: 701px) {
  .ClientCodePage {
    .clientCodeCard {
      width: calc(100% - 50px) !important;
    }
    .formFieldContainer {
      width: 100% !important;
      .infoIcon {
        display: none !important;
      }
    }
  }
}
