.PaymentAmount {
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 107px;
}

.boxPaymentAmount {
  max-width: 612px;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 12px;
  padding-top: 46px;

  .boxPaymentAmountBody {
    padding: 0;
  }
}

.amountInfoBox {
  padding-left: 83px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
  .label {
    margin-top: 42px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    color: rgba(0, 0, 0, 0.8);
  }

  .value {
    display: flex;
    flex-direction: column;
    margin-top: 34px;
    margin-bottom: 33px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 45px;
    line-height: 53px;
    color: #000000;

    span {
      &:first-child {
        margin-bottom: 12px;
      }
    }
  }
}

.orderInfo {
  margin-top: 47px;
  padding-left: 83px;

  .orderInfoItem {
    display: flex;
    align-items: center;
    .boxImg {
      background: #11578c;
      border-radius: 15px;
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .boxInfoText {
      margin-left: 25px;
      font-family: Roboto;
      font-style: normal;
      .label {
        font-weight: 300;
        font-size: 15px;
        line-height: 18px;
        color: #000000;
      }
      .value {
        padding-top: 8px;
        font-weight: normal;
        font-size: 19px;
        line-height: 22px;
        color: #000000;
      }
    }
  }

  .orderInfoItem + .orderInfoItem {
    margin-top: 36px;
  }
}

.btnContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 463px;
  margin: 78px auto 41px;
  button {
    width: 200px;
  }
}

@media only screen and (max-width: 570px) {
  .PaymentAmount {
    padding-bottom: 20px;
  }
  .boxPaymentAmount {
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding-top: 0;

    .boxPaymentAmountBody {
      padding: 0;
    }

    .amountInfoWrapp {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      border-radius: 12px;
      padding-top: 43px;
      padding-bottom: 73px;
      margin-top: 60px;

      .amountInfoBox {
        padding-left: 33px;
        .label {
          margin-top: 0;
          font-size: 19px;
          line-height: 22px;
        }
        .value {
          margin-top: 15px;
          margin-bottom: 27px;
          font-size: 45px;
          line-height: 53px;
          white-space: nowrap;
        }
      }
    }
  }
  .orderInfo {
    margin-top: 52px;
    padding-left: 33px;
  }

  .btnContainer {
    margin: 80px auto 20px;
  }
}

@media only screen and (max-width: 570px) {
  .btnContainer {
    margin: 40px auto 20px;
    flex-direction: column;
    button {
      width: 100%;
    }
    button + button {
      margin-top: 20px;
    }
  }
  .amountInfoWrapp {
    .amountInfoBox {
      .value {
        font-size: 35px !important;
      }
    }
  }
}
