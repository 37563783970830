.CreditCardRegistrationPage2 {
  .backgroundContainer {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../../../assets/images/grayBubbles.svg);
    background-repeat: no-repeat;
    background-position: left bottom;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-top: -8px;
      margin-bottom: 81px;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      color: #666666;
    }

    .contentCard {
      width: 361px;
      height: 366px;
      margin-bottom: 119px;
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 30px;
      border: none;

      .cardBody {
        padding-top: 44px;
        padding-left: 28px;
        padding-right: 28px;

        .cardTitle {
          margin-bottom: 22px;
          font-style: normal;
          font-weight: bold;
          font-size: 19px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.7);
        }

        .formRowContainer {
          display: flex;
          margin-bottom: 24px;
          width: 308px;

          &:last-child {
            justify-content: space-between;
          }

          .formFieldContainer {
            width: 134px;
          }

          /* .scanButton {
            box-sizing: border-box;
            width: 80px;
            height: 57px;
            margin: 0;
            margin-left: 7px;
            padding: 0;
            padding-top: 25px;
            font-style: normal;
            font-weight: 300;
            font-size: 11px;
            line-height: 13px;
            color: rgba(0, 0, 0, 0.7);
            background: #f5f5f5;
            background-image: url(../../../assets/images/qrIcon.svg);
            background-repeat: no-repeat;
            background-position: center 11px;
            border-radius: 15px;
            border: none;
            outline: none;
          } */
        }
      }
    }

    .buttonContainer {
      width: 310px;
      margin-bottom: 124px;
    }
  }
}
