$main: #11578c;
$label: #404040;
$whitegrey: #b1b1b1;

.CompanyCard {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: 1px solid transparent;
  display: flex;
  //justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 11px 30px 13px 45px;
  cursor: pointer;
}

.borderCard {
  border: 1px solid #11578c;
}

.companyInfo {
  margin-left: 70px;
  .contactBox {
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 4px;
    .label {
      font-weight: 900;
      color: $label;
    }
    .value {
      font-weight: 400;
      color: $whitegrey;
    }
  }

  .inmubleBox {
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    padding-top: 10px;
    padding-bottom: 4px;

    .inmuble {
      font-weight: 900;
      color: $label;
    }
    .inmubleValue {
      font-weight: 400;
      color: $whitegrey;
    }
  }

  .titularBox {
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    .titular {
      font-weight: 900;
      color: rgba(0, 0, 0, 0.75);
    }
    .titularValue {
      font-weight: 400;
      color: $whitegrey;
    }
  }
}

.amountBox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
  font-style: normal;
  font-weight: 900;
  white-space: nowrap;
  .amount {
    display: block;
    text-align: right;
    font-size: 12px;
    line-height: 14px;
    color: $whitegrey;
  }

  .amountvalue {
    font-size: 20px;
    line-height: 24px;
    color: $label;
  }
}

.amountBoxMobile {
  display: none;
}

/* @media only screen and (max-width: 768px) {
  .CompanyCard {
    justify-content: space-around;
    padding: 11px 0 13px;

    .companyInfo {
      margin-left: 10px;
    }
  }
} */

@media only screen and (max-width: 768px) {
  .CompanyCard {
    justify-content: flex-start;
    padding: 11px 0 13px;

    .companyInfo {
      margin-left: 10px;
    }
  }

  .boxImg {
    padding-left: 32px;
    padding-right: 28px;
  }

  .amountBox {
    display: none;
  }

  .amountBoxMobile {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    .amount {
      font-weight: 900;
      color: rgba(0, 0, 0, 0.75);
    }
    .amountValue {
      font-weight: 400;
      color: $whitegrey;
    }
  }
}
