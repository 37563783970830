.SignUpMobilePage {
  .title {
    position: relative;
    top: -18px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #666666;
  }

  .details {
    position: relative;
    top: -20px;
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: rgba(102, 102, 102, 0.75);
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .formFieldContainer {
    width: 310px;
    margin-bottom: 13px;
  }
}
