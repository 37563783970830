.EditProfilePage {
  padding-bottom: 70px;
  .formFieldContainer {
    margin-bottom: 15px;
    &.isValid {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: top 52px right 15px;
    }
  }
}

.col {
  max-width: 453px;
  margin: 0 auto;
}

.tabsContainer {
  margin-top: 30px;
  margin-bottom: 20px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  .tabItem {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.form {
  padding-left: 52px;
  padding-right: 52px;
}

.modal {
  background-color: violet;
}

@media only screen and (max-width: 576px) {
  .EditProfilePage {
    .tabs {
      padding-left: 52px;
      padding-right: 52px;
      text-align: center;
    }
  }
}
