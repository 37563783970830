.AppContainerWithArcBackgraund {

  .ArcTopBackground {
    position: absolute;
    z-index: -1;
    top: 0;
    width: 100%;
    height: 407px;
    background-image: url(../../../assets/images/blueArcBackground.svg);
    background-repeat: no-repeat;
    background-size: 100% 407px;
  }

  .BubblesBottomBackround {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../../../assets/images/grayBubbles.svg);
    background-repeat: no-repeat;
    background-position: left bottom;
  }
}

@media only screen and (max-width: 570px) {
  .AppContainerWithArcBackgraund {
    .ArcTopBackground {
      height: 260px !important;
      background-size: 100% 260px !important;
    }
  }
}

