$main: #11578c;

.boxImg svg {
  width: 137px;
  height: 138px;
}

.message p {
  margin-bottom: 58px;
}

.btnBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  .buttonLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    svg {
      position: relative;
      top: -2px;
      margin-right: 7px;
    }
  }

  .buttonsContainer {
    width: 336px;
    display: flex;
    justify-content: space-between;
  }

  div {
    box-sizing: border-box;
    border-radius: 10px;
  }

  .cancel {
    margin-right: 32px;
    margin-bottom: 0;
    border: 1px solid $main;
    padding: 12px 49px;
    cursor: pointer;

    span {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: $main;
    }
  }

  .delete {
    background: #fe4141;
    padding: 13px 33px 13px 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      padding-left: 9px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
    }
  }
}

.boxImg {
  margin-top: 53px;
  margin-bottom: 56px;
}

@media only screen and (max-width: 576px) {
  .header {
    padding: 16px 19px 0;
  }

  .boxImg {
    margin-top: 25px;
    margin-bottom: 32px;
    svg {
      width: 86px;
      height: 86px;
    }
  }

  .message p {
    margin-bottom: 31px;
    font-size: 18px;
    line-height: 22px;
  }

  .btnBox {
    margin-top: 31px;
    margin-bottom: 36px;
    flex-direction: column;

    .cancel {
      margin-right: 0;
      margin-bottom: 16px;
      padding: 11px 87px;
    }

    .delete {
      padding: 13px 69px 13px 76px;
    }
  }
}
