.TermsOfUsePage {
  .content {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    max-width: 823px;
    padding-left: 40px;
    padding-right: 40px;
    h1 {
      margin-bottom: 30px;
      text-align: center;
    }
    .buttonContainer {
      display: flex;
      justify-content: center;
      button {
        margin-top: 21px;
        margin-bottom: 51px;
      }
    }
  }
}
