.ContractsGroups {
  padding-right: 30px;
  padding-left: 30px;
}

.boxCompanyGroupList {
  max-width: 828px;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 12px;

  .companyGroups {
    max-height: 570px;
    padding: 10px 92px;
    margin: 40px auto 0;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
      background: #F0F0F0;
      border-radius: 30px;
      height: 107px;
      width: 8px;
    }
  }


  .contact {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    color: #666666;
    margin-bottom: 16px;
  }

  .groupItem + .groupItem {
    margin-top: 46px;
  }

}


@media only screen and (max-width: 768px) {
  .boxCompanyGroupList {
    .companyGroups {
      padding: 10px 26px;
    }
  }
}

@media only screen and (max-width: 570px) {
  .ContractsGroups {
    padding-right: 0;
    padding-left: 0;
  }
  .boxCompanyGroupList {
    background-color: transparent;
    box-shadow: none;

    .companyGroups {
      padding: 10px;
    }

    .contact {
      font-size: 18px;
      line-height: 20px;
    }
  }

}
