.boxImg {
  margin-top: 50px;
  margin-bottom: 60px;
}

.message p {
  margin-bottom: 68px;
}


@media only screen and (max-width: 576px) {
  .header {
    padding: 25px 25px 0;
  
    img {
      width: 14px;
      height: 14px;
    }
  }

  .boxImg {
    margin-top: 35px;
    margin-bottom: 30px;
    img {
      width: 70px;
      height: 70px;
    }
  }

  .message p {
    margin-bottom: 60px;
    font-size: 18px;
    line-height: 22px;
  }
}