.Header {
  width: 100%;
  height: 174px;
  background-image: url(../../assets/images/emptyHeader.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .backButton {
    position: absolute;
    top: 31px;
    left: 24px;
    display: block;
    font-size: 0;
    width: 42px;
    height: 32px;
    background-image: url(../../assets/images/backArrow.svg);
    cursor: pointer;
  }
  .headerContainer {
    position: relative;
    top: -38px;
    width: 1029px;
    height: 72px;
    padding: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;

    a {
      text-decoration: none;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      white-space: nowrap;
    }
  }

  .navLink {
    color: white;
    padding: 0;
    margin-right: 25px;
    &.disabled {
      color: rgba(255, 255, 255, 0.58);
      pointer-events: none;
    }
  }

  .dropDownToggle {
    position: relative;
    color: white;
    margin-right: 49px;
    cursor: pointer;

    &.disabled {
      color: rgba(255, 255, 255, 0.58);
      pointer-events: none;
      &::after {
        background-image: url(../../assets/images/arrowDownDisabled.svg);
      }
    }

    &.active {
      &::after {
        transform: rotate(180deg);
      }
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      right: -24px;
      width: 19px;
      height: 19px;
      border: none;
      background-image: url(../../assets/images/arrowDown.svg);
    }

    .dropDownContent {
      display: none !important;
      position: absolute;
      display: flex;
      flex-direction: column;
      top: -39px;
      left: -20px;
      bottom: -80px;
      padding-top: 39px;
      padding-left: 20px;
      background: rgba(13, 73, 119, 0.56);

      &.visible {
        display: flex !important;
      }

      &.accountDropDown {
        width: 115px;
      }

      &.dashboardDropDown {
        width: 127px;
      }

      span {
        margin-bottom: 10px;
      }

      a {
        margin-bottom: 8px;
        color: white;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }

  .clip {
    position: absolute;
    z-index: 1;
    top: 14px;
    left: 0;
    right: 0;
    height: 144px;
    background-image: url(../../assets/images/headerClip.svg);
    background-repeat: no-repeat;
    background-size: 100% 144px;
    pointer-events: none;

    &.blue {
      background-image: url(../../assets/images/headerBlueClip.svg);
    }
  }
}
