:root {
  --color: 0, 123, 255;
}

.PhoneField {
  //margin-top: 26px;
  display: flex;
  align-items: flex-end;
  height: 93px;
  margin-bottom: 0;
  font-family: 'Lato', sans-serif;
  .label {
    position: absolute;
    top: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
  }

  .phoneContainer{
    width: 100%;
    select + div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 22px;
      color: #666666;
    }
    div + div {
      width: 9px;
      height: 9px;
      margin-left: 10px;
      margin-right: 10px;
    }
    input {
      box-sizing: border-box;
      height: 65px;
      padding-left: 20px;
      padding-right: 20px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      color: #666666;
      background-color: transparent;
      border: 1px solid #666666;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;

      &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(var(--color), 25%);
      }
    }
  }
  .phoneContainer > div {
    border: 1px solid #666666;
    border-right: 0px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    margin-right: 0;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 962px) {
  .PhoneField {
    height: 80px;
    .phoneContainer{
      input {
        height: 52px !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
        font-size: 14px !important;
        line-height: 17px !important;
      }
    }
    
    .label {
      font-size: 14px !important;
      line-height: 17px !important;
    }
  }
}
