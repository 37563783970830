$main: #11578C;
$white: #ffffff;

.HomePage {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../../assets/images/ellipseLeft.svg'), url('../../assets/images/ellipseTop.svg');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: $main;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}


.HomePageWrap {
  max-width: 453px;
  width: 100%;
}

.logo {
  display: none;
}

.title {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: $white;
}
  
.description {
  margin-top: 21px;
  margin-bottom: 86px;
  font-size: 30px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.53);
}

.btnWrapp {
  button + button {
    margin-top: 18px;
  }
}

@media (max-width: 962px) and (max-height:822px) {
  .HomePage {
    position: static;
  }
}


@media only screen and (max-width: 962px) {
  .HomePage {
    background: url('../../assets/images/ellipseMobileLeft.svg'), url('../../assets/images/ellipseMobileTop.svg');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: $main;
    flex-direction: column;
    padding: 130px 52px 144px;
  }

  .logo {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 34px;
    line-height: 41px;
    margin-top: 91px;
  }

  .description {
    margin-top: 22px;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 84px;
  }

  .btnWrap {
    padding-bottom: 20px;
  }
}

