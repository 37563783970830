.StyledButton {
  position: relative;
  width: 100%;
  height: 65px;
  background: #11578c;
  //border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border: none;
  border-radius: 10px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  overflow: hidden;

  .click {
    //display: none !important;
    position: absolute;
    z-index: 111;
    bottom: 0;
    left: 50%;
    display: block;
    width: 57px;
    height: 86%;
    background: rgba(255, 255, 255, 0.31);
    border-radius: 50%;
  }

  &.narrow {
    height: 47px;
  }

  &:focus {
    background: #11578c;
    box-shadow: 0 0 0 5px rgba(30, 79, 252, 0.37);
    box-sizing: border-box;
    border: none;
  }

  &:hover {
    background: #08395f;
  }

  &:disabled {
    background: rgba(17, 87, 140, 0.59);
    border: none;
    &.isLoading {
      background: #11578c;
    }
  }

  &:active {
    &:not(:disabled) {
      .click {
        // display: block !important;
      }
    }
  }

  &.secondary {
    color: #11578c;
    background-color: transparent;
    border: 1px solid #11578c;
    box-sizing: border-box;
    border-radius: 10px;
    &:hover {
      background-color: inherit;
      border: 1px solid #08395f;
      color: #08395f;
    }

    .click {
      background: rgba(17, 87, 140, 0.37) !important;
    }
    &:active {
      border: 1px solid #1e4ffc;
      .click {
        background: rgba(17, 87, 140, 0.37) !important;
      }
    }
    &:focus {
      background-color: inherit;
      box-shadow: 0 0 0 5px rgba(30, 79, 252, 0.12);
      box-sizing: border-box;
      border: 1px solid #11578c;
    }
    &:disabled {
      background-color: inherit;
      border: 1px solid rgba(17, 87, 140, 0.59);
      color: rgba(17, 87, 140, 0.59);
      &.isLoading {
        color: #11578c;
        background-color: transparent;
        border: 1px solid #11578c;
      }
    }
  }

  &.subtle {
    background-color: transparent;
    border: 1px solid #d6d8e7;
    color: #11578c;
    .click {
      background: rgba(13, 73, 119, 0.56) !important;
    }
    &:hover {
      border: 1px solid #08395f;
      background: #08395f;
    }
    &:focus {
      box-shadow: 0 0 0 5px rgba(13, 73, 119, 0.56);
    }
    &:active {
      .click {
        background: rgba(13, 73, 119, 0.56) !important;
      }
    }
    &:disabled {
      background-color: transparent;
      border: 1px solid rgba(219, 220, 234, 0.59);
      color: rgba(17, 87, 140, 0.59);
      &.isLoading {
        border: 1px solid #d6d8e7;
        color: #11578c;
      }
    }
  }

  &.text {
    background-color: transparent;
    color: #11578c;
    &:hover {
      color: #08395f;
    }
    &:focus {
      box-shadow: none;
      color: #11578c;
    }
    &:active {
      color: #11578c;
    }
    &:disabled {
      background-color: transparent;
      color: rgba(17, 87, 140, 0.59);
      &.isLoading {
        color: #11578c;
      }
    }
  }

  &.white {
    background-color: white;
    color: #11578c;
    .click {
      background: rgba(17, 87, 140, 0.37) !important;
    }
    &:hover {
      color: #08395f;
    }
    &:focus {
      box-shadow: none;
    }
    &:active {
      .click {
        background: rgba(17, 87, 140, 0.37) !important;
      }
    }
  }

  &.grayBorder {
    border: 1px solid #c2c2c2;
    color: #ffffff;
  }
}

@media only screen and (max-width: 962px) {
  .FormButton {
    height: 52px;
  }
}

.spinner {
  display: inline-block;
  position: relative;
  width: 26px;
  height: 26px;
  .loadingMessage {
    display: none;
  }
  &.text {
    width: 76px;
    .loadingMessage {
      display: block;
    }
    div {
      display: none;
    }
  }
  &.secondary {
    div {
      border: 2px solid #11578c;
      border-color: #11578c transparent transparent transparent;
    }
  }
  &.subtle {
    div {
      border: 2px solid #11578c;
      border-color: #11578c transparent transparent transparent;
    }
  }
}
.spinner div {
  position: relative;
  top: 3px;
  left: 2px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  margin: 2px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
