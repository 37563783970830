.LoginPage {
  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-bottom: 38px;
      font-style: normal;
      font-weight: bold;
      font-size: 35px;
      line-height: 42px;
      text-align: center;
      color: #666666;
    }
    .loginForm {
      width: 453px;
      .formFieldContainer {
        margin-bottom: 15px;
        &.lastOne {
          margin-bottom: 14px;
        }
        .loginError {
          position: relative;
          padding-left: 17px;
          margin-bottom: 9px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #f0491f;
          &::before {
            content: '';
            position: absolute;
            top: 5px;
            left: 0;
            display: block;
            width: 8px;
            height: 8px;
            background: #f0491f;
            border-radius: 50%;
          }
        }
      }
      .checkBoxContainer {
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
        .forgotPassword {
          position: relative;
          top: -6px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #666666;
          text-decoration: none;
        }
      }
      .buttonContainer {
        margin-bottom: 162px;
      }
    }
  }
}

@media only screen and (max-width: 570px) {
  .LoginPage {
    height: 896px;
    overflow: hidden;
    .contentContainer {
      position: relative;
      top: -180px;
      .loginForm {
        width: 310px;
        .checkBoxContainer {
          .forgotPassword {
            top: -7px;
            font-size: 12px;
            line-height: 14px;
          }
        }
        .buttonContainer {
          margin-bottom: 109px;
        }
      }
    }
  }
}
