.ArcTopBackground {
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 284px;
  background-image: url(../../../assets/images/blueArcSmallBackground.svg);
  background-repeat: no-repeat;
  background-size: 100% 284px;
}

.BubblesBottomBackround {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../../../assets/images/grayBubbles.svg);
  background-repeat: no-repeat;
  background-position: left bottom;
}

.ContractsPage {
  .boxWrapper {
    padding-left: 39px;
    padding-right: 39px;
  }
  .title h1 {
    margin-top: -25px;
    color: #ffffff;
    font-size: 45px;
    line-height: 54px;
    font-weight: bold;
    text-align: center;
  }

  .contracts {
    max-width: 1029px;
    margin: 150px auto;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
  }
  .contractItem {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 45px 35px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    cursor: pointer;
  }

  .label {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    color: #666666;
  }

  .description {
    margin-top: 8px;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 26px;
    color: #666666;
  }

  .addContractBtn {
    background: #11578c;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding-top: 49px;
    padding-left: 53px;
    padding-right: 35px;
    padding-bottom: 48px;
    cursor: pointer;

    .text {
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 38px;
      padding-left: 39px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .ContractsPage {
    .title h1 {
      font-size: 36px;
      line-height: 43px;
    }

    .contracts {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 2rem;
    }

    .contractItem {
      flex-direction: column;
      padding: 20px 35px 10px;
    }

    .label {
      margin-top: 15px;
      text-align: center;
      font-size: 18px;
      line-height: 22px;
    }
    .description {
      display: none;
    }

    .addContractBtn {
      flex-direction: column;
      padding: 20px 35px 10px;
      .text {
        margin-top: 15px;
        text-align: center;
        font-size: 18px;
        line-height: 22px;
        padding-left: 0;
      }
    }
  }
}

@media only screen and (max-width: 570px) {
  .ArcTopBackground {
    height: 260px !important;
    background-size: 100% 260px !important;
  }
}
