.RegistrationPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Lato', sans-serif;

  .backgroundContainer {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../../../assets/images/grayBubbles.svg);
    background-repeat: no-repeat;
    background-position: left bottom;
  }

  .registrationForm {
    width: 669px;
    margin-bottom: 66px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 12px;

    .formTitle {
      font-style: normal;
      font-weight: bold;
      font-size: 35px;
      line-height: 42px;
      text-align: center;
      color: #666666;
    }

    .details {
      margin-bottom: 19px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #666666;
    }

    .registrationFormBody {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 17px;
    }

    .formFieldContainer {
      width: 453px;
      margin-bottom: 15px;
      &.isValid {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: top 50px right 15px;
      }
    }
  }
}

@media only screen and (max-width: 962px) {
  .registrationForm {
    width: 500px !important;
  }
}
