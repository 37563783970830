.modal {
  background: rgba(0, 0, 0, 0.32);
  padding-left: 37px;
  padding-right: 37px;
  padding-bottom: 50px;
}

.contentBox {
  max-width: 548px;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.header {
  border-bottom-style: none;
  padding: 30px 40px 0;
  display: flex;
  justify-content: flex-end;

  div {
    cursor: pointer;
  }

  img {
    width: 23px;
    height: 23px;
  }
}

.body {
  text-align: center;

  p {
    font-size: 24px;
    line-height: 29px;
    color: #666666;
  }
}

@media only screen and (max-width: 570px) {
  .header {
    border-bottom-style: none;
    padding: 25px 25px 0;
    display: flex;
    justify-content: flex-end;
  
    img {
      width: 18px;
      height: 18px;
    }
  }
}


@media only screen and (max-width: 475px) {
  .modal {
    padding-left: 0;
    padding-right: 0;
  }
}