.PasswordResetPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  .contentContainer {
    position: relative;
    top: -17px;
    width: 453px;
    .details {
      width: 310px;
      margin-bottom: 30px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #666666;
    }
    .formFieldContainer {
      margin-bottom: 93px;
    }

    .buttonContainer {
      margin-bottom: 11px;
      &.lastOne {
        margin-bottom: 120px;
      }
    }
  }
}

@media only screen and (max-width: 570px) {
  .PasswordResetPage {
    .contentContainer {
      top: 0;
      width: 310px;
      margin-top: 49px;
      .details {
        margin-bottom: 37px;
      }
      .formFieldContainer {
        margin-bottom: 68px;
      }
      .buttonContainer {
        margin-bottom: 25px;
        &.lastOne {
          margin-bottom: 100px;
        }
      }
    }
  }
}
