.AppContainerWithMenu {
  .backgroundContainer {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../../../assets/images/grayBubbles.svg);
    background-repeat: no-repeat;
    background-position: left bottom;
  }
}

