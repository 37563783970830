.ArcTopBackground {
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 284px;
  background-image: url(../../../assets/images/blueArcSmallBackground.svg);
  background-repeat: no-repeat;
  background-size: 100% 284px;
}

.BubblesBottomBackround {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../../../assets/images/grayBubbles.svg);
  background-repeat: no-repeat;
  background-position: left bottom;
}

.CreditCardRegistrationPage {
  .boxCreditCardRegistration {
    max-width: 512px;
    width: 100%;
    margin: 80px auto 40px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 12px;
    
    .boxCreditCardRegistrationBody {
      padding: 0;
    }
  }
  .title {
    margin-top: -25px;
    color: #FFFFFF;
    text-align: center;
    h1 {
      color: #FFFFFF;
      font-size: 45px;
      line-height: 54px;
      font-weight: bold;
      margin-bottom: 0;
    }
    p {
      font-size: 24px;
      line-height: 25px;
    }

  }

  .form {
    max-width: 360px;
    margin: 50px auto;
    padding-left: 20px;
    padding-right: 20px;
    .formFieldContainer {
      margin-top: 15px;
      input {
        background-color: #FFFFFF;
      }
    }
    .phoneWithOutSelect {
      display: none;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .CreditCardRegistrationPage {
    .title {
      h1 {
        margin-bottom: 10px;
      }
    }
  }
}

@media only screen and (max-width: 570px) {
  .ArcTopBackground {
    height: 260px !important;
    background-size: 100% 260px !important;
  }

  .CreditCardRegistrationPage {
    .boxCreditCardRegistration {
      background-color: transparent;
      box-shadow: none;
      border: none;
      padding-top: 0;
      .boxCreditCardRegistrationBody {
        padding: 0;
      }
    }
    .title {
      margin-top: -20px;
      h1 {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        line-height: 19px;
      }
    }
    .form {
      .formFieldContainer {
        button {
          height: 52px;
        }
        .dropDown {
          background-color: #fff;
        }
      }
      .phoneWithSelect {
        display: none;
      }
      .phoneWithOutSelect {
        display: block;
      }

    }
  }

}